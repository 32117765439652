<template>
  <CHeader fixed class="align-items-center custom-header" >
    <!--CCol lg="4" col="6">
      <h4>SSC CGL 001</h4>
    </CCol-->
      <!--CToggler v-if="start" in-header class="ml-3 d-lg-none"/-->
      <CHeaderNavItem v-if="timer" class="d-flex mx-auto">
        <CIcon name="cil-clock" size="lg" class="mr-2" />
        <h5>
          <span>{{ formatTime(examRemi.hr) }} hr</span> :
          <span>{{ formatTime(examRemi.min) }} min</span> :
          <span class="text-danger">{{ formatTime(examRemi.sec) }}</span> sec
        </h5>
      </CHeaderNavItem>
      <CHeaderNav class="mr-3   " v-if="timer">
        <CButton color="success" @click="$store.state.warning=true">Submit Test</CButton>
      </CHeaderNav>
  </CHeader>
</template>

<script>
import { localDb } from "@/mixins/localDb";
import { mapGetters } from 'vuex';

export default {
  name: "Header",
  mixins: [localDb],
  data() {
    return {
      examRemi: {
        sec: 0,
        hr: 0,
        min: 0,
      },
      examData: {},
      countDownDate: 0,
      clock: Function,
    };
  },
  computed:{
    ...mapGetters({
      timer:'timer'
    })
  },
  watch: {
    timer: function () {
      if (this.timer) {
        this.examData = this.get("examData", "local");
        const timeLeft = this.get("timeLeft", "session");
        this.startCountdown(timeLeft);
      } else {
        //when timer is false during exam, save remaining time in session
        const timeLeft =
          (this.examRemi.hr * 60 * 60 +
            this.examRemi.min * 60 +
            this.examRemi.sec) *
          1000;
        this.save("timeLeft", timeLeft, "session");
        clearInterval(this.clock);
      }
    },
  },
  methods: {
    startCountdown(timeLeft = null) {
      let d = new Date();
      let time = this.examData.testData.testDuration;
      const timeGap = timeLeft || time * 60000;
      this.countDownDate = d.getTime() + timeGap;
      this.clock = setInterval(() => {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = this.countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        this.examRemi = {
          hr: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          min: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          sec: Math.floor((distance % (1000 * 60)) / 1000),
        };

        // If the count down is finished, end test
        if (distance < 0) {
          this.$store.commit('setTimer',false);
          this.$store.commit('setExamStatus',false);
          this.save("isExamInProgress", false, "session");
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.custom-header {
  height: 60px;
  box-shadow: 3px 3px 5px 2px #9e9e9e;
  background-color:#31275f;
  color:#fff;
}
</style>